import { GetDataWithOffset } from "./GetDataWithOffset";
import { sumArrays } from '@utils/Arrays';

export const GetGuidelineBalanceOfColor = (contract, wallet, color, balance) => {
    return new Promise((resolve, reject) => {
        GetDataWithOffset(contract.methods.balanceOfColorGl('0x' + wallet.address, 0, color), balance)
            .then(sumArrays)
            .then((result) => {
                return resolve(result);
            }).catch((e) => {
                return reject(e);
            })
    })
    
}