import { passwordCheck } from "@utils/PasswordCheck";
import Web3 from "web3";
import { rpcLink } from "@config/CryptoConfig";

const SignTransactionAction = async (walletData, rawTransaction) => {

    const web3Provider = new Web3.providers.HttpProvider(rpcLink);
    const web3 = new Web3(web3Provider);

    rawTransaction = JSON.parse(atob(rawTransaction));

    return new Promise(async (resolve, reject) => {
        passwordCheck().then(async (password) => {
            if(!password) return reject(4);

            try{
                const wallet = web3.eth.accounts.decrypt(JSON.stringify(walletData), password, true);
                if(!wallet){
                    return reject(5);
                }

                var tx = await web3.eth.accounts.signTransaction(rawTransaction, wallet.privateKey).catch((e) => {
                    return reject(e)
                });

                const receipt = await web3.eth.sendSignedTransaction(tx.rawTransaction).catch((e) => {
                    // console.log(e);
                    return reject(e);
                });

                return resolve(receipt);
            } catch(e) {
                return reject(e);
            }
        
        })
        .catch((e) => {
            return reject(e);
        })
    })

    

}

export default SignTransactionAction;