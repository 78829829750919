import React from "react";
import { useSelector } from "react-redux";
import SignMessage from "@components/wallet/actions/oauth/SignMessage";
import './login.scss';
import Swal from "sweetalert2";
import { useSearchParams } from "react-router-dom";

const Login = () => {

    const wallets = useSelector(state => state.walletReducer.wallets);
    const wallet = JSON.parse(wallets[0]);

    const [searchParams] = useSearchParams();
    const message = searchParams.get('message');

    const signMessage = async () => {
        SignMessage(wallet, message)
            .then((data) => {
                // window.opener.postMessage(data, process.env.REACT_APP_PLATFORM_URL);
                data.address = wallet;
                window.opener.postMessage(data, document.referrer);
                window.close();
            })
            .catch((e) => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: e,
                })
                console.log(e);
            })
    }

    return (
        <div>
            <div style={{color: '#fff'}}>
                Авторизоваться используя кошелек
            </div>
            <br />
            <br />
            <div style={{color: '#fff'}}>
                {wallet.address}
            </div>
            <br />

            <div className="messageBlock">
                <span className="messageBlock__hint">Sign message for auntification</span>
                {message}
            </div>

            <br />
            <br />
            <div style={{display: 'flex', gap: 10}}>
                <div style={{flex: 1}}>
                    <button className="oauth-button" onClick={() => signMessage()}>Sign message</button>
                </div>
                <div style={{flex: 1}}>
                    <button className="oauth-button oauth-button__cancel" onClick={() => window.close()}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default Login;