import React, { createContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Send from "@components/wallet/actions/Send";
import GetBalance from "@components/wallet/actions/GetBalance";
import { coins } from '@config/CryptoConfig';
export const CoinContext = createContext();

const CoinProvider = ({children}) => {

    const transactions = useSelector(state => state.transactionReducer.transactions);
    const wallets = useSelector(state => state.walletReducer.wallets);
    const wallet = JSON.parse(wallets[0]);
    const dispatch = useDispatch();
    const [balance, setBalance] = useState([]);

    const updateBalanceAllCoins = async () => {

        const promises = [];
        const balanceTmp = [];

        coins.map((coin) => {
            promises.push(new Promise(async (resolve, reject) => {
                await GetBalance(coin, wallet).then((result) => {
                    return resolve({coin: coin, balance: result});
                }).catch((e) => {
                    // //console.log(e);
                    return reject(e);
                })
            }))
        });

        await Promise.all(promises.map(promise => 
            promise.then((data) => {
                balanceTmp[data.coin] = data.balance;
                return data;
            }).catch((e) => {
                // //console.log(e);
            })
        )).catch((e) => {
            // //console.log(e);
        })

        // console.log(balanceTmp);

        setBalance(balanceTmp);
    }

    const undateCoinBalance = (coin) => {
        const balanceTmp = balance;
        GetBalance(coin, wallet).then((result) => {
            balanceTmp[coin] = result;
            setBalance(balanceTmp);
        }).catch((e) => {
            //console.log(e);
        })
    }

    useEffect(() => {
        //console.log('Update all coin balance');
        updateBalanceAllCoins();
    }, [])

    const send = (coin) => {
        Send(coin, wallet).then((receipt) => {
            dispatch({type: 'ADD_TRANSACTION', payload: receipt});
            updateBalanceAllCoins();
        }).catch((e) => {
            //console.log(e);
        })
    }

    return (
        <CoinContext.Provider value={{
            transactions,
            send,
            balance,
            undateCoinBalance
        }}>
            {children}
        </CoinContext.Provider>
    )
}

export default CoinProvider;