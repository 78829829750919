import React, {useContext} from "react"

import NftCard from "./NftCard";

import { useParams } from "react-router-dom";

const NftCards = () => {

    const { color } = useParams();

    return (
        <div className={'alphabet_ma__collection ma__collection-' + color} >
            <div className="maCollection__content">
                {[...Array(40)].map((x, i) =>
                    <NftCard key={i} guidline={i} />
                )}
            </div>
        </div>
    );
}

export default NftCards;