import React, {useState, useContext} from "react";
import { AlphabetContext } from "@providers/AlphabetContext";
import Swal from "sweetalert2";
import {SpinnerWhite} from '@components/spinner';
import Web3 from "web3";
import { aristippus } from "@config/CryptoConfig";
import { sendPrice, sendAktPrice } from "@config/AlphabetConfig";

const Activate = ({data}) => {

    // const { wallet, redirect } = useContext(AlphabetContext);
    // const [loading, setLoading] = useState(false);

    return(
        <>
            {data.act == 0 ?
                <button type="button" className={`collectionBttn2 collectionBttn2-active`}>
                    Activate on the Platform
                </button>
            :
                <button type="button" className={`collectionBttn collectionBttn-active`}>
                    Activated
                </button>
            }
        </>
    )
}

export default Activate;