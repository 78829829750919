import GosCoinABI from "@config/ABI/GosCoinABI";
import GicoCoinABI from "@config/ABI/GicoCoinABI";
import DaoCoinABI from "@config/ABI/DaoCoinABI";
import DaoACoinABI from "@config/ABI/DaoACoinABI";
import NftCollectorABI from "@config/ABI/NftCollectorABI";
import AristippusNftCombiner from "@config/ABI/AristippusNftCombiner";
import AristippusNftActions from "@config/ABI/AristippusNftActions";
import AlphabetABI from "@config/ABI/AlphabetABI";

export const rpcLink = process.env.REACT_APP_RPC_LINK;

export const coins = ['GOS', 'GICO', 'GICO_PROGRESS', 'DAO', 'DAOA'];

export const coinsConfig = {
    "GOS": {
        "coin": "GOS",
        "title": "GOS COIN",
        "contractAddress": process.env.REACT_APP_GOS,
        "minABI": GosCoinABI,
        "transferFee": false,
        "coinVideo": "/video/coin.mp4",
    },
    "GICO": {
        "coin": "GICO",
        "title": "GICO COIN",
        "contractAddress": process.env.REACT_APP_GICO,
        "minABI": GicoCoinABI,
        "transferFee": true,
        "background": "#141950",
    },
    "GICO_PROGRESS": {
        "coin": "GICO_PROGRESS",
        "title": "GICO COIN PROGRESS",
        "contractAddress": process.env.REACT_APP_GICO_PROGRESS,
        "minABI": GicoCoinABI,
        "transferFee": true,
        "background": "#141950",
    },
    "DAO": {
        "coin": "DAO",
        "title": "GLOBAL RENAISSANCE DAO",
        "contractAddress": process.env.REACT_APP_DAO,
        "minABI": DaoCoinABI,
        "transferFee": true,
        "background": "#1ba600",
    },
    "DAOA": {
        "coin": "DAOA",
        "title": "GLOBAL RENAISSANCE DAO ADVANCED",
        "contractAddress": process.env.REACT_APP_DAOA,
        "minABI": DaoACoinABI,
        "transferFee": true,
        "background": "#fee985"
    },

    "NFT_COLLECTOR": {
        "contractAddress": process.env.REACT_APP_NFT_COLLECTOR,
        "minABI": NftCollectorABI,
    },
}


export const aristippus = {
    4: {
        "minABI": AlphabetABI,
        "minABICombiner": AristippusNftCombiner,
        "minABIActions": AristippusNftActions,
        "contractAddress": process.env.REACT_APP_ALPHABET,
        "contractAddressCombiner": process.env.REACT_APP_ALPHABET_COMBINER,
        "title": "ALPHABET",
        "slug": "alphabet-token/yellow",
        "baseUrl": "alphabet-token",
        "metaWebsiteUrl": process.env.REACT_APP_ARISTIPPUS_NFT_META_WEBSITE + '/alphabet',
    },
}