import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Web3 from "web3";
import { rpcLink } from "@config/CryptoConfig";
import { useSelector } from "react-redux";

const AristippusWalletCard = ({data}) => {

    const [balance, setBalance] = useState(0);

    const web3Provider = new Web3.providers.HttpProvider(rpcLink);
    const web3 = new Web3(web3Provider);
    const contract = new web3.eth.Contract(data.minABI, data.contractAddress);

    const wallets = useSelector(state => state.walletReducer.wallets);
    const wallet = JSON.parse(wallets[0]);

    // console.log('Link', Link);

    useEffect(() => {

        contract.methods.balanceOf('0x' + wallet.address).call().then((result) => {
            setBalance(result);
        }).catch((e) => {
            // console.log(e);
        })

    }, [])

    return (
        <>
            <div className="nftBlock__wrapper">
                <div className="nftBlock">
                    <div className="walletCheck walletCheck-nft">
                        <div className="walletCheck__content">
                            <div className="walletCheck__name">
                                {data.title}
                            </div>
                            <div className="nft__right">

                                <Link to={data.slug} className="aristippusNft-link">
                                    <div className="walletCheck__btnWrap">
                                        <div className="walletBttn walletBttn-receive">
                                            <span className="walletBttn__icon">
                                                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 52"><path d="M33.57 3.5a1 1 0 1 0-.68 1.88 23 23 0 1 1-15.87.03 1 1 0 1 0-.69-1.87 25 25 0 1 0 17.23 0ZM15.02 25a1 1 0 0 0-.29.71 1 1 0 0 0 .3.71L23.82 35a1.82 1.82 0 0 0 2.55 0l8.56-8.56a1 1 0 0 0 0-1.42 1 1 0 0 0-1.41 0l-7.42 7.42V1a1 1 0 0 0-2 0v31.48L16.46 25a1 1 0 0 0-1.44 0Z" fill="#05a84a"></path></svg>
                                            </span>
                                            <span className="walletBttn__label">Open</span>
                                        </div>
                                    </div>
                                </Link>
                                
                                <div className="walletCheck__amountNft">
                                    <div className="walletCheckAmountNft__label">
                                        Amount:
                                    </div>
                                    <div className="walletCheckAmountNft__value">
                                        {balance} <span className="walletCheckAmountNft__value-span">MA</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </>
    )

}

export default AristippusWalletCard;