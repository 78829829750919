import React from "react";
import './index.scss';

const DefaultWrapper = ({children}) => {
    return(
        <div className="defaultWrapper">
            <div className="container">
                <div className="content">
                    <div className="content__wrap">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DefaultWrapper;