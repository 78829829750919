import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Web3 from "web3";
import bip39 from "bip39js";
import { ethers } from "ethers";
import Swal from "sweetalert2";
import withReactContent from 'sweetalert2-react-content'
import { saveAs } from "file-saver";

import MnemonicCheck from "@components/auth/create/MnemonicCheck";
import { passwordInput } from "@utils/PasswordInput";
import CardSimple from "@components/cards/CardSimple";

const CreateWallet = () => {
   
    const MySwal = withReactContent(Swal)

    const web3 = new Web3();

    const [mnemonic, setMnemonic] = useState('');
    const [mnemonicShuffled, setMnemonicShuffled] = useState('');
    const [mnemonicShuffledArray, setMnemonicShuffledArray] = useState([]);
    const [successCheck, setSuccessCheck] = useState(false);
    const [wallet, setWallet] = useState({});
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();

    const create = () => {

        passwordInput(false).then((password) => {
            if(password){
                setPassword(password);
                passwordInput(true, password).then((password2) => {
                    if(password2){

                        let size = 128;
                        let entropy = bip39.genEntropy(size);
                        let mnemonicTmp = bip39.genMnemonic(entropy);

                        if(bip39.validMnemonic(mnemonicTmp)){

                            let mnemonicArray = mnemonicTmp.split(' ');
                            let shuffled = mnemonicArray.map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value)
                            let shufledString = shuffled.join(' ');

                            setMnemonic(mnemonicTmp);
                            setMnemonicShuffled(shufledString);

                            setMnemonicShuffledArray(mnemonicArray);
                            // setMnemonicShuffledArray(shufledString.split(' '));
                        }
                    }
                })
            }
        }).catch((e) => {

        })  
    };

    useEffect(() => {
        if(mnemonic === '' || mnemonicShuffled === '' || mnemonicShuffledArray.length <= 0) return false;
        const wallet = ethers.Wallet.fromMnemonic(mnemonic);
        setWallet(wallet);
    }, [mnemonic, mnemonicShuffled, mnemonicShuffledArray])

    useEffect(() => {
        if(!(wallet instanceof ethers.Wallet)) return false;

        mnemonicShow().then((showResult) => {
            if(showResult){
                mnemonicCheck();
            }
        })	
    }, [wallet])

    const callback = (process) => {
		let percent = Math.round(process * 100);
		Swal.update({
			text: `Прогресс создания: ${percent}% 🚀`,
            allowOutsideClick: false
		})

		if(process === 1){
			Swal.close();
		}
	}

    

    const mnemonicShow = async () => {
        return await MySwal.fire({
            icon: 'warning',
            title: 'Запомните слова',
            html: <MnemonicShow />,
            showConfirmButton: true,
            showCancelButton: true,
            allowOutsideClick: false,
        }).then((result) => {
            return result.isConfirmed;
        })
    }

    const MnemonicShow = () => {

        var isFileSaverSupported = false;
        try {
            isFileSaverSupported = !!new Blob();
        } catch (e) {}

        return (
            <>
                Запишите данные слова, они вам очень пригодятся!<br /><br />
                {printWords()} <br />

                {isFileSaverSupported &&
                    <div className="downloadButton" onClick={() => downloadMnemonic()}>Скачать .txt файл</div>
                }
            </>
        )
    }

    const downloadMnemonic = () => {
        try {
            var blob = new Blob([mnemonic], {type: "text/plain;charset=utf-8"});
            saveAs(
                blob,
                "mnemonic.txt"
            );
        } catch (e) {}
    }
    
    const downloadKeyfile = (json) => {
        try {
            var blob = new Blob([json], {type: "application/json"});
            saveAs(
                blob,
                "private_key.txt"
            );
        } catch (e) {}
    }



    const mnemonicCheck = async () => {
        return await MySwal.fire({
            icon: 'warning',
            title: 'Выберите слова',
            html: <MnemonicCheck setSuccessCheck={setSuccessCheck} successCheck={successCheck} mnemonicShuffledArray={mnemonicShuffledArray} mnemonic={mnemonic} />,
            showConfirmButton: successCheck,
            showCancelButton: true, 
            showClass: {
                backdrop: 'swal2-noanimation', // disable backdrop animation
                popup: '',                     // disable popup animation
                icon: ''                       // disable icon animation
            },
            hideClass: {
                popup: '',                     // disable popup fade-out animation
            },
            allowOutsideClick: false,
        }).then((result) => {
            return result.isConfirmed;
        })
    }

    useEffect(() => {
        if(successCheck === true) {
            mnemonicCheck().then((checkResult) => {
                if(checkResult){
                    
                    const json = web3.eth.accounts.encrypt(wallet.privateKey, password);

                    const aaa = web3.eth.accounts.decrypt(json, password)
                    // console.log(aaa);


                    var isFileSaverSupported = false;
                    try {
                        isFileSaverSupported = !!new Blob();
                    } catch (e) {}

                    MySwal.fire({
                        icon: 'warning',
                        title: 'Внимание',
                        html: <>
                            Обязательно скачайте данный файл, в дальнейшем он позволит получить доступ к вашему кошельку 👇🏻 <br /><br />
                            {isFileSaverSupported &&
                                <div className="downloadButton" onClick={() => downloadKeyfile(JSON.stringify(json))}>Скачать .txt файл</div>
                            }
                        </>,
                        showConfirmButton: true,
                        allowOutsideClick: false,
                    }).then((result) => {
                        if(result.isConfirmed){

                            Swal.fire({
                                title: 'Создание вашего нового кошелька', 
                                text: 'Прогресс создания: 0% 🚀',
                                allowOutsideClick: false,
                                showCancelButton: false,
                                showConfirmButton: false,
                            })

                            wallet.encrypt(password, callback).then((walletJson) => {
                                dispatch({type: 'IMPORT_WALLET', payload: walletJson});
                            });  
                            
                        }
                    })
            
 
                }
            });
        }
    }, [successCheck])
  
    const printWords = () => {
        let mnemonicArray = mnemonic.split(' ');
        return (
            <div className="mnemonicWords__wrap">
                {mnemonicArray.map((item, index) => {
                    return <div key={index} className="mnemonicWords__item">{index + 1}. {item}</div>
                })}
            </div>
        )
    }


    return (
        <CardSimple 
            title="Create new wallet"
            subtitle="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
            onClick={() => create()}
        />
    )
}

export default CreateWallet;