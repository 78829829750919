import React from "react";
import './index.scss';

const CardSimpleHover = ({title, subtitle, onClick, elements}) => {
    return(
        <div className="card cardHover" onClick={onClick}>
            <div className="carhHover__front">
                <div className="card__title">{title}</div>
                <div className="card__content">{subtitle}</div>
            </div>
            <div className="carhHover__back">
                {elements}
            </div>
        </div>
    )
}

export default CardSimpleHover;