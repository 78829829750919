import Swal from "sweetalert2"

export const AmountInput = async (balance, ccy) => {
    return await Swal.fire({
        icon: 'warning',
        title: `Send Amount`,
        text: 'Enter amount to transfer',
        input: 'text',
        inputAttributes: {
            autocapitalize: 'off',
            placeholder: 'Amount',
        },
        showCancelButton: true,
        confirmButtonText: 'Continue',
        showLoaderOnConfirm: true,
        backdrop: true,
        inputValidator: (value) => {
            if(isNaN(value) || isNaN(parseFloat(value))){
                return 'Incorrect amount';
            }

            if(countDecimals(value) >= 9){
                return 'The amount can contain no more than 8 decimal places';
            }
        },
        preConfirm: (amount) => {
            if(amount.length <= 0){
                Swal.showValidationMessage(`This field is required`)
            }
            if(parseFloat(amount) > balance){
                Swal.showValidationMessage(`Maximal available amount ${balance.toFixed(8)}`)
            }
            if(!isFinite(amount) || isNaN(parseFloat(amount))){
                Swal.showValidationMessage(`Incorrect amount`)
            }

            if(Number(parseFloat(amount)).toFixed(8) < 0.00000001){
                Swal.showValidationMessage(`Incorrect amount`)
            }
        },
        // allowOutsideClick: () => !Swal.isLoading()
        allowOutsideClick: false
    }).then((result) => {
        if (result.isConfirmed) {
            if(result.value){
                return result.value;
            }
        }
        return false;
    })
}

const countDecimals = (value) => {
    if(Math.floor(value) === value) return 0;
    const numberParts = value.toString().split(".");
    if(numberParts[1]){
        return value.toString().split(".")[1].length || 0; 
    }

    return 0;
    
}