import React from "react";
import { Link } from "react-router-dom";
import DefaultWrapper from "@components/wrappers/DefaultWrapper";
import Navbar from "@components/navbars/Navbar";

import AlphabetContext from "@providers/AlphabetContext";
import CoinContext from "@providers/CoinContext";

import Detail from "@components/wallet/aristippus/series4/Detail";
import { useNavigate } from "react-router-dom";
// import '@components/wrappers/styles/aristippus_wisdom.scss';

const AristippusDetailPage = () => {

    const navigate = useNavigate();

    return(
        <CoinContext>
            <AlphabetContext>
                <DefaultWrapper>
                    <Navbar />
                    <div className="mainWrapper">
                        <div onClick={() => navigate(-1)} className="back__btn">← back</div>
                        <div className="mainWrapper__content mainWrapper__content-wisdom">
                            <Detail />
                        </div>
                    </div>
                </DefaultWrapper>
            </AlphabetContext>
        </CoinContext>
    )

}

export default AristippusDetailPage;