import Swal from "sweetalert2"

export const passwordCheck = async () => {
    return await Swal.fire({
        icon: 'warning',
        title: 'Your password',
        input: 'password',
        inputAttributes: {
            autocapitalize: 'off',
            placeholder: 'Password',
        },
        showCancelButton: true,
        confirmButtonText: 'Continue',
        showLoaderOnConfirm: true,
        backdrop: true,
        preConfirm: (password) => {
            if(password.length <= 0){
                Swal.showValidationMessage(`This field is required`)
                return false;
            }

            if(password.length < 8){
                Swal.showValidationMessage(`Minimal password length is 8 characters`)
                return false;
            }            
        },
        allowOutsideClick: false
        // allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
        if (result.isConfirmed) {
            if(result.value){
                return result.value;
            }
        }
        return false;
    })
}