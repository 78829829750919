import { passwordCheck } from "@utils/PasswordCheck";
import Web3 from "web3";
import { rpcLink } from "@config/CryptoConfig";

const SignMessage = async (walletData, message) => {

    const web3Provider = new Web3.providers.HttpProvider(rpcLink);
    const web3 = new Web3(web3Provider);

    return new Promise(async (resolve, reject) => {
        passwordCheck().then(async (password) => {
            if(!password) return reject(4);

            try{
                const wallet = web3.eth.accounts.decrypt(JSON.stringify(walletData), password, true);
                if(!wallet){
                    return reject(5);
                }

                const signedMessage = await web3.eth.accounts.sign(message, wallet.privateKey);
                return resolve(signedMessage);
            } catch(e) {
                return reject(e);
            }
        
        })
        .catch((e) => {
            return reject(e);
        })
    })

    

}

export default SignMessage;