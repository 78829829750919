import React from "react";
import Swal from "sweetalert2";
import * as Icon from "react-icons/fi";
import { Wallet, getDefaultProvider } from "ethers";
import { useDispatch } from "react-redux";
import bip39 from "bip39js";
import { ethers } from "ethers";

import { passwordInput } from "@utils/PasswordInput";

const RestoreByMnemonicPhrase = () => {

	const dispatch = useDispatch();

	const restoreUsingMnemonicPhrase = () => {

		Swal.fire({
			icon: 'question',
			title: 'Restore wallet',
			text: 'Введите 12 секретных слов',
			input: 'text',
			inputAttributes: {
				autocapitalize: 'off',
				placeholder: 'Mnemonic phrase',
			},
			showCancelButton: true,
			confirmButtonText: 'Continue',
			showLoaderOnConfirm: true,
			backdrop: true,
			preConfirm: (mnemonic) => {
				if(mnemonic === ''){
					Swal.showValidationMessage(`This field is required`)
					return false;
				}

				if(mnemonic.split(' ').length !== 12){
					Swal.showValidationMessage(`Надо ввести 12 слов`)
					return false;
				}

				if(!bip39.validMnemonic(mnemonic)){
					Swal.showValidationMessage(`Не верные слова, попробуй еще раз`)
					return false;
				}
			},
			allowOutsideClick: false
			// allowOutsideClick: () => !Swal.isLoading()
		}).then((result) => {
			if (result.isConfirmed) {
				if(result.value){
					if(bip39.validMnemonic(result.value)){
						const wallet = ethers.Wallet.fromMnemonic(result.value);
						
						passwordInput(false).then((password) => {
							if(password){
								passwordInput(true, password).then((password2) => {
									if(password2){
										Swal.fire({
											title: 'Importing your wallet', 
											text: 'Import progress: 0% 🚀',
											allowOutsideClick: false,
											showCancelButton: false,
											showConfirmButton: false,
										})

										wallet.encrypt(password, callback).then((json) => {
											dispatch({type: 'IMPORT_WALLET', payload: json});
										});
									}
								})
							}
						})
					}
				}
			}
		})
	}

	const callback = (process) => {
		let percent = Math.round(process * 100);
		Swal.update({
			text: `Import progress: ${percent}% 🚀`
		})

		if(process === 1){
			Swal.close();
		}
	}

	return (
		<div className="carhHover__back-item" onClick={() => restoreUsingMnemonicPhrase()}>
			<Icon.FiFileText className="carhHover__back-icon"/>
            <span className="carhHover__back-itemText">Mnemonic phrase</span>
		</div>
	)
}

export default RestoreByMnemonicPhrase;