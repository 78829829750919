import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import HomePage from "@pages/home/HomePage";
import TransactionsPage from "@pages/home/TransactionsPage";

import {default as AristippusPageAlphabet} from "@pages/home/alphabet_nft/AristippusPage";
import {default as AristippusCollectionPageAlphabet} from "@pages/home/alphabet_nft/AristippusCollectionPage";
import {default as AristippusDetailPageAlphabet} from "@pages/home/alphabet_nft/AristippusDetailPage";

import { aristippus } from "@config/CryptoConfig";
import OAuthPage from "@pages/home/oauth/OAuthPage";
import SignTrans from "@pages/home/oauth/SignTrans";

const AppNavigation = () => {

    return (
        <Routes>
            <Route path="/">
                <Route index element={<HomePage />} />
                <Route path="transactions/:coin" element={<TransactionsPage />} />
                <Route path="authentication" element={<OAuthPage />} />
                <Route path="sign-transaction" element={<SignTrans />} />

                <Route exact path={`${aristippus[4]['baseUrl']}/:color`} element={<AristippusPageAlphabet />} />
                <Route exact path={`${aristippus[4]['baseUrl']}/:color/collection/:guidline`} element={<AristippusCollectionPageAlphabet />} />
                <Route exact path={`${aristippus[4]['baseUrl']}/:color/collection/:guidline/:index`} element={<AristippusDetailPageAlphabet />} />
                <Route exact path={aristippus[4]['baseUrl']} element={<Navigate to={`/${aristippus[4]['baseUrl']}/yellow`} />}/>
            </Route>
        </Routes>
    )

}

export default AppNavigation;