import React from "react";
import DefaultWrapper from "@components/wrappers/DefaultWrapper";
import NavbarSimple from "@components/navbars/NavbarSimple";
import SignTransaction from "@components/wallet/oauth/SignTransaction";

const SignTrans = () => {

    return (
        <DefaultWrapper>
            <NavbarSimple />
            <div className="mainWrapper">
                <div className="mainWrapper__content">
                    <SignTransaction />
                </div>
            </div>
        </DefaultWrapper>
    )

}

export default SignTrans;