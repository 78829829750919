import React, { createContext, useEffect, useState } from 'react'
import { aristippus, rpcLink } from '@config/CryptoConfig';
import Web3 from 'web3';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from "react-router-dom";

import { GetBalance } from '@components/wallet/aristippus/utils/GetBalance';
import { GetCountByColor } from '@components/wallet/aristippus/utils/GetCountByColor';
import { GetGuidelineBalanceOfColor } from '@components/wallet/aristippus/utils/GetGuidelineBalanceOfColor';
import { GetGuidelineColorItems } from '@components/wallet/aristippus/utils/GetGuidelineColorItems';
import { GetTokenDetail } from '@components/wallet/aristippus/utils/GetTokenDetail';
import { GetFarmingPrice } from '@components/wallet/aristippus/utils/GetFarmingPrice';

export const AlphabetContext = createContext();

const AlphabetProvider = ({children}) => {

    const navigate = useNavigate();
    const { color, guidline, index } = useParams();
    const dispatch = useDispatch();

    const balance = useSelector(state => state.alphabetReducer.balance)
    const farmingFee = useSelector(state => state.alphabetReducer.farmingFee)
    const nftColorData = useSelector(state => state.alphabetReducer.nftColorData);

    const nftColorGuidelineData = useSelector(state => state.alphabetReducer.nftColorGuidelineData);
    const nftCollectionData = useSelector(state => state.alphabetReducer.nftCollectionData);

    const [loaded, setLoaded] = useState(false);
    const [loadedCollection, setLoadedCollection] = useState(false);
    const [connectionError, setConnectionError] = useState(false);
    
    const colors = {
        'yellow': 0,
        'red': 1,
        'green': 2,
        'purple': 3,
        'blue': 4,
    }

    const totalByColor = [
        1000,
        1000,
        1000,
        1000,
        1000
    ];

    const { minABI, contractAddress, minABICombiner, contractAddressCombiner } = aristippus[4];

    const wallets = useSelector(state => state.walletReducer.wallets);
    const wallet = JSON.parse(wallets[0]);

    const web3Provider = new Web3.providers.HttpProvider(rpcLink);
    const web3 = new Web3(web3Provider);
    const contractCombiner = new web3.eth.Contract(minABICombiner, contractAddressCombiner);
    const contract = new web3.eth.Contract(minABI, contractAddress);

    // useEffect(() => {
    //     GetFarmingPrice(contract)
    //         .then((result1) => {
    //             dispatch({type: 'SET_ALNFT_FARMING_FEE', payload: result1})
    //         })
    //         .catch((e) => {
    //             console.log(e);
    //         });
        
    // }, []);

    useEffect(() => {

        if(guidline || index) {
            setLoaded(true);
            return false;
        }

        GetBalance(contract, wallet)
            .then((result1) => {
                
                dispatch({type: 'SET_ALNFT_BALANCE', payload: result1})
                GetCountByColor(contractCombiner, wallet, result1)
                    .then((result2) => {

                        //console.log('result2', result2);

                        dispatch({type: 'SET_ALNFT_COLOR_DATA', payload: result2})

                        GetGuidelineBalanceOfColor(contractCombiner, wallet, color ? colors[color] : 0, result1)
                            .then((result3) => {
                                dispatch({type: 'SET_ALNFT_COLOR_GUIDELINE_DATA', payload: result3});
                                setLoaded(true);
                            })
                            .catch((e) => {
                                //console.log(e);
                                setConnectionError(true);
                            })
                    })
                    .catch((e) => {
                        //console.log(e);
                        setConnectionError(true);
                    })
            })
            .catch((e) => {
                //console.log(e);
                dispatch({type: 'SET_ALNFT_BALANCE', payload: 0});
                setConnectionError(true);
            });
    }, []);

    useEffect(() => {
        if(!color || balance == null) return false;

        GetGuidelineBalanceOfColor(contractCombiner, wallet, colors[color], balance)
            .then((result) => {
                dispatch({type: 'SET_ALNFT_COLOR_GUIDELINE_DATA', payload: result});
                setLoaded(true);
            })
            .catch((e) => {
                //console.log(e);
                setConnectionError(true);
            })

    }, [color]);

    useEffect(() => {
        if(!color || !guidline || balance == null) return false;
        getTokenDetailInformation();

    }, [color, guidline]);

    const getTokenDetailInformation = () => {

        GetBalance(contract, wallet)
            .then((result1) => {
                dispatch({type: 'SET_ALNFT_BALANCE', payload: result1})

                GetGuidelineColorItems(contractCombiner, wallet, colors[color], guidline, result1)
                    .then((result2) => {

                        // console.log('result2', result2);

                        GetTokenDetail(result2, contract, wallet)
                            .then((result3) => {
                                dispatch({type: 'SET_ALNFT_COLLECTION_DATA', payload: result3});
                                setLoadedCollection(true);
                            }).catch((e) => {
                                //console.log(e);
                            })
                    })
                    .catch((e) => {
                        //console.log(e);
                        setConnectionError(true);
                    })
            })
            .catch((e) => {
                //console.log(e);
                setConnectionError(true);
            })
    }

    const redirect = (link) => {
        navigate(link, {replace: true});
    }
   
    return (
        <AlphabetContext.Provider value={{
            loaded,
            connectionError,

            nftColorData,
            nftColorGuidelineData,
            totalByColor,
            nftCollectionData,
            loadedCollection,
            farmingFee,

            wallet,
            getTokenDetailInformation,
            redirect
        }}>
            {children}
        </AlphabetContext.Provider>
    )
}

export default AlphabetProvider;