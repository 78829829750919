import React, {useContext} from "react";
import { AlphabetContext } from "@providers/AlphabetContext";
import { useParams, Link, Navigate } from "react-router-dom";
// import NotFound from "../informers/NotFound";
// import NumberFormat, { FarmedFormat } from "@utils/NumberFormat";
// import { Romanize } from "@utils/Number";
// import { BackgroundImage } from 'react-image-and-background-image-fade';
import { titleData } from "@config/AristippusWisdomConfig";

// import Collect from "../actions/collect";
// import Send from "../actions/send";
// import Farm from "../actions/Farm";
// import Collect from "../actions/Collect";
// import Send from "../actions/Send";
// import { aristippus } from "@config/CryptoConfig";
import Activate from '../actions/Activate';

const CollectionBlock = ({index}) => {

    const { color, guidline } = useParams();
    const {nftCollectionData, loadedCollection} = useContext(AlphabetContext);

    if(!loadedCollection){
        return <></>
    }

    const data = nftCollectionData[index];

    const image = (guidline) + '.png';

    const isNitro = parseFloat(data.nitro) > 0 ? true : false;
    const isCombo = parseFloat(data.bns) > 1000 ? true : false;
    const isAdvanced = parseFloat(data.akt) > 0 ? true : false;
    const hasMultiplyer = isNitro || isCombo || isAdvanced ? true : false;
    const totalMultiplyer = parseFloat(data.bns) + parseFloat(data.nitro) + parseFloat(data.akt);

    return (
        <div className="collectionRow">
            <div className="collectionRow__number">
                <b>{data.Number}</b>
            </div>
            <div className="collectionRow__icon">
                <img src={require(`@images/alphabet/icons/${image}`)} />
            </div>
            <div className="collectionRow__title">{titleData[guidline]['title']}</div>
            <div className="collectionRow__button">
                <Activate data={data} />
            </div>
        </div>
    )

}

export default CollectionBlock;