import React from "react";
import './navbar.scss';

const NavbarSimple = () => {
    return(
        <div className="navbar">
            <div className="navbar__logo">
                <div className="navbar__logo-link">
                    <div className="navbar__logo-text">GR Wallet</div>
                </div>
            </div>
        </div>
    )
}

export default NavbarSimple;