import React, { useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { AlphabetContext } from "@providers/AlphabetContext";
import { Romanize } from "@utils/Number";
import NumberFormat, { FarmedFormat } from "@utils/NumberFormat";
import { titleData } from "@config/AlphabetConfig";
import NotFound from "./NotFound";
import AlphabetWrapper from "@components/wrappers/AlphabetWrapper";

import Farm from "./actions/Farm";
import Burn from "./actions/Burn";
import Collect from "./actions/Collect";
import Send from "./actions/Send";
import { aristippus } from "@config/CryptoConfig";

const Detail = () => {

    const { color, guidline, index } = useParams();
    const { nftColorGuidelineData, nftCollectionData } = useContext(AlphabetContext);

    if(!nftColorGuidelineData || !nftColorGuidelineData[guidline - 1] || !nftCollectionData || !nftCollectionData[index]){
        return (
            <AlphabetWrapper>
                <NotFound />
            </AlphabetWrapper>
        )
    }

    // const image = (guidline) + '.png';
    // const data = nftCollectionData[index];

    // const isNitro = parseFloat(data.nitro) > 0 ? true : false;
    // const isCombo = parseFloat(data.bns) > 1000 ? true : false;
    // const isAdvanced = parseFloat(data.akt) > 0 ? true : false;
    // const hasMultiplyer = isNitro || isCombo || isAdvanced ? true : false;
    // const totalMultiplyer = parseFloat(data.bns) + parseFloat(data.nitro) + parseFloat(data.akt);

    return (
        <div className="errorInformer">
            There are no entries in this section.
        </div>
    )

}

export default Detail;