import React from "react";
import { Link } from "react-router-dom";
import DefaultWrapper from "@components/wrappers/DefaultWrapper";
import Navbar from "@components/navbars/Navbar";

import AlphabetContext from "@providers/AlphabetContext";
import CoinContext from "@providers/CoinContext";

import Collection from "@components/wallet/aristippus/series4/Collection";
import { useNavigate } from "react-router-dom";

const AristippusCollectionPage = () => {

    const navigate = useNavigate();

    return (
        <CoinContext>
            <AlphabetContext>
                <DefaultWrapper>
                    <Navbar />
                    <div className="mainWrapper">
                        <div onClick={() => navigate(-1)} className="back__btn">← back</div>
                        <div className="mainWrapper__content">
                            <Collection />
                        </div>
                    </div>
                </DefaultWrapper>
            </AlphabetContext>
        </CoinContext>
    )

}

export default AristippusCollectionPage;