import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { aristippus } from "@config/CryptoConfig";
import { AlphabetContext } from "@providers/AlphabetContext";

const Header = () => {

    let { color } = useParams();
    const { nftColorData } = useContext(AlphabetContext)

    return (
        <>
        <div className="alphabet_ma__top">
            <div className="alphabet_maTop__left">
                <div className="alphabet_maMenu">
                    <Link to={`/${aristippus[4]['baseUrl']}/yellow`} className={`alphabet_maMenu__link alphabet_maMenu__link-yellow ${color == 'yellow' ? 'alphabet_maMenu__link-active' : ''}`}>
                    yellow ({nftColorData[0] ? nftColorData[0] : 0})
                    </Link>
                    <Link to={`/${aristippus[4]['baseUrl']}/red`} className={`alphabet_maMenu__link alphabet_maMenu__link-red ${color == 'red' ? 'alphabet_maMenu__link-active' : ''}`}>
                        red ({nftColorData[1] ? nftColorData[1] : 0})
                    </Link>
                    <Link to={`/${aristippus[4]['baseUrl']}/green`} className={`alphabet_maMenu__link alphabet_maMenu__link-green ${color == 'green' ? 'alphabet_maMenu__link-active' : ''}`}>
                        green ({nftColorData[2] ? nftColorData[2] : 0})
                    </Link>
                    <Link to={`/${aristippus[4]['baseUrl']}/purple`} className={`alphabet_maMenu__link alphabet_maMenu__link-purple ${color == 'purple' ? 'alphabet_maMenu__link-active' : ''}`}>
                        purple ({nftColorData[3] ? nftColorData[3] : 0})
                    </Link>
                    <Link to={`/${aristippus[4]['baseUrl']}/blue`} className={`alphabet_maMenu__link alphabet_maMenu__link-blue ${color == 'blue' ? 'alphabet_maMenu__link-active' : ''}`}>
                        blue ({nftColorData[4] ? nftColorData[4] : 0})
                    </Link>
                </div>
            </div>
        </div>
        </>
    )

}

export default Header;