import React, { useContext } from "react";
import './styles/alphabet.scss';
import { AlphabetContext } from "@providers/AlphabetContext";

import Header from "@components/wallet/aristippus/series4/wrapper/Header";
import Footer from "@components/wallet/aristippus/series4/wrapper/Footer";

const AlphabetWrapper = ({children, mainPage}) => {

    const { loaded, connectionError, nftData } = useContext(AlphabetContext)

    if(!loaded){
        return (
            <></>
        )
    }

    return(
        <div className="series4Nft">
            <div className={connectionError ? 'metaAristippus blurContent' : 'metaAristippus'}>
                <div className="container">
                    <div className="alphabet_ma__wrapper">
                        <img src={require(`@images/alphabet/header.jpg`)} className="alphabet_maContent__header" alt="" />

                        <div className="alphabet_ma__content">
                            {mainPage &&
                                <div className="alphabet_ma__contentText">
                                    <p><b>NFT series with a set of fundamental interaction principles</b></p>

                                    <p>Success never happens on its own, it is always the result of interaction. To achieve the desire, one needs to communicate, cooperate, make compromises and joint decisions.</p>

                                    <p>The quality of interaction is the main factor that today determines the scale and profitability of a business. It was this factor that became the central idea of NFT Interaction Alphabet.</p>

                                    <p><b>The unique NFT series — the alphabet for a new language of interaction in modern society.</b></p>
                                </div>
                            }

                            <Header />
                                <div className="alphabet_ma__main">
                                    {children}
                                </div>
                            <Footer />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlphabetWrapper;