import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import SignTransactionAction from "@components/wallet/actions/oauth/SignTransactionAction";
import './login.scss';
import Swal from "sweetalert2";
import { useSearchParams } from "react-router-dom";

const SignTransaction = () => {

    const wallets = useSelector(state => state.walletReducer.wallets);
    const wallet = JSON.parse(wallets[0]);
    // const [searchParams] = useSearchParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const transaction = searchParams.get("transaction");

    useEffect(() => {
        if(!transaction) window.close();
    }, []);

    const signTransaction = () => {
        console.log(transaction);
        SignTransactionAction(wallet, transaction)
            .then((data) => {
                // console.log(data);
                // window.opener.postMessage(data, process.env.REACT_APP_PLATFORM_URL);
                window.opener.postMessage(data, document.referrer);
                window.close();
            })
            .catch((e) => {
                console.log(e);
            })
    }

    // const message = "Authenticate access to wallet";

    // const signMessage = async () => {
    //     SignMessage(wallet, message)
    //         .then((data) => {
    //             window.opener.postMessage(data, process.env.REACT_APP_PLATFORM_URL);
    //             window.close();
    //         })
    //         .catch((e) => {
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: 'Error',
    //                 text: e,
    //             })
    //             console.log(e);
    //         })
    // }

    return (
        <div>
            <div style={{color: '#fff'}}>
                Авторизоваться используя кошелек
            </div>
            <br />
            <br />
            <div style={{color: '#fff'}}>
                {wallet.address}
            </div>
            <br />

            <div className="messageBlock">
                <span className="messageBlock__hint">Sign message for auntification</span>
                <span style={{wordWrap: 'break-word', fontSize: 11}}>{transaction}</span>
            </div>

            <br />
            <br />
            <div style={{display: 'flex', gap: 10}}>
                <div style={{flex: 1}}>
                    <button className="oauth-button" onClick={() => signTransaction()}>Sign transaction</button>
                </div>
                <div style={{flex: 1}}>
                    <button className="oauth-button oauth-button__cancel" onClick={() => window.close()}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default SignTransaction;