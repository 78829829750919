import Swal from "sweetalert2"

export const IntermediatePreloader = async () => {

    return await Swal.fire({
        title: `In processing`,
        timer: 1000,
        // timerProgressBar: true,
        didOpen: () => {
            Swal.showLoading()
        },
    }).then((result) => {
        return true;
    })
}