import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store/store';
import { Provider } from 'react-redux'
import { BrowserRouter, HashRouter } from "react-router-dom";
import '@styles/reset.scss';
import '@styles/css.scss';

import { Buffer } from 'buffer'
window.Buffer = Buffer

if (process.env.REACT_APP_DEV != "1") {
    const console = {};
    console.log = function () { };
    console.warn = function () { };
    console.error = function () { };
    console.table = function () { };
    console.info = function () { };
    console.debug = function () { };
    window.console = console;
}

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

reportWebVitals();