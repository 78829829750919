import React from "react";
import Swal from "sweetalert2";
import * as Icon from "react-icons/fi";
import { Wallet, getDefaultProvider } from "ethers";
import { useDispatch } from "react-redux";

import { passwordInput } from "@utils/PasswordInput";

const RestoreUsingPrivateKey = () => {

	const dispatch = useDispatch();

	const restoreUsingPrivateKey = () => {

		Swal.fire({
			icon: 'question',
			title: 'Restore wallet',
			text: 'Restore wallet using private key',
			input: 'text',
			inputAttributes: {
				autocapitalize: 'off',
				placeholder: 'Private key',
			},
			showCancelButton: true,
			confirmButtonText: 'Continue',
			showLoaderOnConfirm: true,
			backdrop: true,
			preConfirm: (privateKey) => {
				if(privateKey.length <= 0){
					Swal.showValidationMessage(`This field is required`)
					return false;
				}
				try{
					let provider = getDefaultProvider();
					let walletWithProvider = new Wallet(privateKey, provider);
					return walletWithProvider;
				} catch (e){
					Swal.showValidationMessage(`Incorrect private key`)
					return false;
				}
			},
			// allowOutsideClick: () => !Swal.isLoading()
			allowOutsideClick: false
		}).then((result) => {
			if (result.isConfirmed) {
				if(result.value){
					let wallet = result.value;
					if(wallet){
						passwordInput(false).then((password) => {
							Swal.fire({
								title: 'Importing your wallet', 
								text: 'Import progress: 0% 🚀',
								allowOutsideClick: false,
								showCancelButton: false,
								showConfirmButton: false,
							})

							wallet.encrypt(password, callback).then((json) => {
								dispatch({type: 'IMPORT_WALLET', payload: json});
							});
						})
					}
				}
			}
		})
	}

	const callback = (process) => {
		let percent = Math.round(process * 100);
		Swal.update({
			text: `Import progress: ${percent}% 🚀`
		})

		if(process === 1){
			Swal.close();
		}
	}

	return (
		<div className="carhHover__back-item" onClick={() => restoreUsingPrivateKey()}>
			<Icon.FiLock className="carhHover__back-icon" />
			<span className="carhHover__back-itemText">Private Key</span>
		</div>
	)
}

export default RestoreUsingPrivateKey;