import React, {useContext} from "react";
import { AlphabetContext } from "@providers/AlphabetContext";
import { useParams } from "react-router-dom";
// import NotFound from "./informers/NotFound";

import NumberFormat from "@utils/NumberFormat";
import { Romanize } from "@utils/Number";
import CollectionBlock from "./collection/CollectionBlock";
import AlphabetWrapper from "@components/wrappers/AlphabetWrapper";
import { titleData } from "@config/AlphabetConfig";
import NotFound from "./NotFound";
import Loading from "./Loading";

const Collection = () => {

    const { color, guidline } = useParams();
    const { nftCollectionData, loadedCollection } = useContext(AlphabetContext)

    if(!loadedCollection){
        return (
            <AlphabetWrapper>
                <Loading />
            </AlphabetWrapper>
        );
    }

    const count = nftCollectionData.length;
    if(!count){
        return (
            <AlphabetWrapper>
                <NotFound />
            </AlphabetWrapper>
        )
    }

    return (
        <AlphabetWrapper>
            <div className={`series-color-${color}`}>
                <div className="alphabet_ma__collection alphabet_ma__collectionPage">
                    {[...Array(count)].map((x, i) =>
                        <CollectionBlock key={i} index={i} />
                    )}
                </div>
            </div>
        </AlphabetWrapper>
    )

}

export default Collection;