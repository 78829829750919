import Web3 from "web3";
import { rpcLink, coinsConfig } from "@config/CryptoConfig";
import NumberFormat from "@utils/NumberFormat";

const GetBalance = (ccy, walletData) => {

    const { minABI, contractAddress, transferFee } = coinsConfig[ccy];

    const web3Provider = new Web3.providers.HttpProvider(rpcLink);
    const web3 = new Web3(web3Provider);
    const contract = new web3.eth.Contract(minABI, contractAddress);

    return new Promise(async(resolve, reject) => {
        try{
            await contract.methods.balanceOf('0x' + walletData.address).call().then((result) => {
                return resolve((result / 100000000).toFixed(8));
            }).catch((e) => {
                return reject(e);
            })
        } catch (e) {
            return reject(e);
        }
    })
}

export default GetBalance;