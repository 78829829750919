import React from "react";
import { Routes, Route } from "react-router-dom";
import AuthPage from '@pages/auth/AuthPage';
// import OAuthPage from "@pages/home/oauth/OAuthPage";

const AuthNavigation = () => {

    return (
        <Routes>
            <Route path="/" element={<AuthPage />}>
                <Route index element={<AuthPage />} />
                <Route path="authentication" element={<AuthPage />} />
                <Route path="sign-transaction" element={<AuthPage />} />
            </Route>
        </Routes>
    )

}

export default AuthNavigation;