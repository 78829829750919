import React from "react";
import Swal from "sweetalert2";
import * as Icon from "react-icons/fi";
import { Wallet, getDefaultProvider } from "ethers";
import { useDispatch } from "react-redux";
import bip39 from "bip39js";
import { ethers } from "ethers";
import Web3 from "web3";

import { passwordInput } from "@utils/PasswordInput";

const RestoreByKeyFile = () => {

	const dispatch = useDispatch();

	const restoreUsingKeyFile = () => {

		Swal.fire({
			icon: 'question',
			title: 'Restore wallet',
			text: 'Введите содержимое json файла, который скачали при создании кошелька',
			input: 'text',
			inputAttributes: {
				autocapitalize: 'off',
				placeholder: 'Содержимое файла',
			},
			showCancelButton: true,
			confirmButtonText: 'Continue',
			showLoaderOnConfirm: true,
			backdrop: true,
			preConfirm: (keyfile) => {
				if(keyfile === ''){
					Swal.showValidationMessage(`This field is required`)
					return false;
				}

				try {
					JSON.parse(keyfile);
				} catch (e) {
					Swal.showValidationMessage(`Incorrect JSON format`)
					return false;
				}
			},
			// allowOutsideClick: () => !Swal.isLoading()
			allowOutsideClick: false
		}).then((result) => {
			if (result.isConfirmed) {
				if(result.value){
					
					passwordInput(false).then((password) => {
						if(password){

							const web3 = new Web3();
							try{
								const wallet = web3.eth.accounts.decrypt(result.value, password, true)
								
								Swal.fire({
									title: 'Importing your wallet', 
									text: 'Import progress: 0% 🚀',
									allowOutsideClick: false,
									showCancelButton: false,
									showConfirmButton: false,
								})

								let provider = getDefaultProvider();
								let walletObject = new Wallet(wallet.privateKey, provider);

								walletObject.encrypt(password, callback).then((json) => {
									dispatch({type: 'IMPORT_WALLET', payload: json});
								});
							} catch (e) {
								// console.log(e);
							}
							
							
							
							
						}
					});
				}
			}
		})
	}

	const callback = (process) => {
		let percent = Math.round(process * 100);
		Swal.update({
			text: `Import progress: ${percent}% 🚀`
		})

		if(process === 1){
			Swal.close();
		}
	}

	return (
		<div className="carhHover__back-item" onClick={() => restoreUsingKeyFile()}>
            <Icon.FiKey className="carhHover__back-icon"/>
            <span className="carhHover__back-itemText">Key file</span>
		</div>
	)
}

export default RestoreByKeyFile;