import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { aristippus } from "@config/CryptoConfig";

import Gos from "@components/wallet/coins/Gos";
import Gico from "@components/wallet/coins/Gico";
import Dao from "@components/wallet/coins/Dao";
import DaoA from "@components/wallet/coins/DaoA";

import AristippusWalletCard from "@components/wallet/aristippus/AristippusWalletCard";
import CoinContext from "@providers/CoinContext";

import "./address_card.scss";
import "./index.scss";

const MyWallet = () => {

    const [copyProcess, setCopyProcess] = useState(false);

    const wallets = useSelector(state => state.walletReducer.wallets);
    const wallet = JSON.parse(wallets[0]);
    
    const copyText = (text) => {
        navigator.clipboard.writeText(text)
        setCopyProcess(true);
        setTimeout(() => {
            setCopyProcess(false);
        }, 1500)
    }

    return (
        <div>
            <br />
            <div className="header__title">
                <div className="headerTitle__left">
                    <b>Wallet address:</b>
                </div>
                <div className="headerTitle__right">
                    {!copyProcess ?
                        <div>
                            {'0x' + wallet.address}
                        </div>
                    :
                        <div style={{color: '#3abdfd'}}>Address copied to clipboard</div>
                    }
                    <button className="headerTitle__btnCopy" onClick={() =>  copyText(`${'0x' + wallet.address}`)}>
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.97 28.03"><path d="M15.5 4.89H2.57A2.53 2.53 0 0 0 0 7.38v18.16A2.53 2.53 0 0 0 2.57 28H15.5a2.54 2.54 0 0 0 2.57-2.49V7.38a2.54 2.54 0 0 0-2.57-2.49Zm1 20.65a1 1 0 0 1-1 1H2.57a1 1 0 0 1-1-1V7.38a1 1 0 0 1 1-1H15.5a1 1 0 0 1 1 1ZM20.39 0H7.47A2.53 2.53 0 0 0 4.9 2.49a.76.76 0 0 0 1.52 0 1 1 0 0 1 1-1h12.97a1 1 0 0 1 1.05 1v18.16a1 1 0 0 1-1.05 1 .74.74 0 1 0 0 1.48A2.54 2.54 0 0 0 23 20.65V2.49A2.54 2.54 0 0 0 20.39 0Z" fill="#2c1d55"></path></svg>
                    </button>
                </div>
            </div>

            <div className="myWallets__wrap">
                <CoinContext>
                    <Gos />
                    {/* <Gico />
                    <Dao />
                    <DaoA /> */}
                </CoinContext>
            </div>

            <br />
            
            {Object.keys(aristippus).map((series, key) => {
                return <AristippusWalletCard key={key} data={aristippus[series]} />
            })}
            

            <br className="mobile__br" />
            <br className="mobile__br" />
            <br />
            <br />
            <br />

        </div>
    )
}

export default MyWallet;