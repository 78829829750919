import React, { useContext, useEffect } from "react";
import { AlphabetContext } from "@providers/AlphabetContext";
import AlphabetWrapper from "@components/wrappers/AlphabetWrapper";
import { Link, useParams } from "react-router-dom";
import NftCards from "./main/NftCards";
import { aristippus } from "@config/CryptoConfig";


const AristippusNft = () => {

    const { color } = useParams();
    const { loaded, nftColorData, totalByColor } = useContext(AlphabetContext);

    if(!loaded){
        return(
            <div style={{color: '#fff', marginTop: 50}}>Please wait. Loading your NFT's ...</div>
        )
    }

    return (
        <AlphabetWrapper mainPage={true}>

                <div className="alphabet_ma__mainWrap">

                    <NftCards />

                </div>

        </AlphabetWrapper>
    )

}

export default AristippusNft;