import React from "react";
import Login from "@components/wallet/oauth/Login";
import DefaultWrapper from "@components/wrappers/DefaultWrapper";
import NavbarSimple from "@components/navbars/NavbarSimple";

const OAuthPage = () => {
    return (
        <DefaultWrapper>
            <NavbarSimple />
            <div className="mainWrapper">
                <div className="mainWrapper__content">
                    <Login />
                </div>
            </div>
        </DefaultWrapper>
    )
}

export default OAuthPage;